import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from '../styles/Home.module.scss';
import stylesN from '../styles/News.module.scss';
import { Autoplay, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { useEffect, useState } from 'react';
import Swiperthumbs from './swiperthumbs';
import { getPlaceholderImage } from '../services/utils';
export default function NewsBannerSwiper({ featuredArticlesJson, isHomePage = false }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [bannerWidth, setBannerWidth] = useState(1133);
    const [progress, setProgress] = useState(0);
    const autoPlayDuration = 4000;
    useEffect(() => {
        // add listener for resize
        window.addEventListener('resize', () => {
            adjustBannerWidth();
        });
        // orientation change
        window.addEventListener('orientationchange', function () {
            adjustBannerWidth();
        });
        adjustBannerWidth();
    }, [activeSlide, thumbsSwiper]);
    const adjustBannerWidth = () => {
        var _a, _b;
        const mainBanner = document.getElementById('newsBannerSwiper');
        let activeSlide = 0;
        if (mainBanner != null) {
            activeSlide = (_b = (_a = mainBanner.getElementsByClassName('slider-content')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.clientWidth;
        }
        setBannerWidth(activeSlide + 40);
    };
    const handleRedirect = (url) => {
        window.location.href = url;
    };
    const getSlidePerView = () => {
        if ((featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.length) === undefined) {
            return 1.1;
        }
        else if ((featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.length) > 1) {
            return 1.1;
        }
        else {
            return 1;
        }
    };
    const onAutoplayTimeLeft = (s, time) => {
        // Calculate the percentage of time left based on the total autoplay duration
        const percentage = (time / autoPlayDuration) * 100;
        let rounded = Math.abs(percentage - 100);
        if (rounded <= 100)
            setProgress(rounded);
    };
    return (_jsxs(_Fragment, { children: [_jsx("style", { children: `
            @media (max-width: 540px) {
                .headerSwiper {
                    height: 54rem !important;
                    padding-bottom: 60px;
                }
            }
          
            .newsFeaturedArticlesSwiper {
                height: 125px;
                box-sizing: border-box;
                padding: 10px 0;
                position: absolute;
                width: 100%;
                bottom: 0;
                margin-bottom: 45px;
                margin-left: auto;
                margin-right: auto;
                max-width: var(--max-width-md);
                width: 100%;

                @media (max-width: 856px) {
                    display: none;
                }
            }

            .headerSwiper {
                height: 100%;
                width: 100%;

              .swiper-button-prev,
            .swiper-button-next {
              width: 3.4rem;
              height: 3.4rem;
              background: rgba(255, 255, 255, 0.50);
              backdrop-filter: blur(5px);
              border-radius: 50%;
              margin-top: -1.7rem;
              margin-left: 6%;
              transition: all .35s ease;
              &:hover {
                background-color: rgba(255, 255, 255, 1);
              }

              @media (max-width: 1400px) {
                position: relative !important;
                width: 3.4rem;
                height: 3.4rem;
                margin-left: 0%;
              }

              &:after {
                content: '';
                display: block;
                width: 2.5rem;
                height: 2.5rem;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.07774 11.8647L19.5577 11.8647C19.8487 11.8647 20.0927 11.9658 20.2896 12.1681C20.4865 12.3703 20.585 12.6209 20.585 12.9199C20.585 13.2189 20.4865 13.4695 20.2896 13.6717C20.0927 13.874 19.8487 13.9751 19.5577 13.9751L8.07774 13.9751L13.1114 19.1456C13.3169 19.3566 13.4154 19.6028 13.4068 19.8842C13.3982 20.1656 13.2912 20.4118 13.0858 20.6229C12.8803 20.8163 12.6406 20.9174 12.3667 20.9262C12.0927 20.935 11.853 20.8339 11.6476 20.6229L4.86746 13.6585C4.76474 13.553 4.69197 13.4387 4.64917 13.3156C4.60636 13.1925 4.58496 13.0606 4.58496 12.9199C4.58496 12.7792 4.60636 12.6473 4.64917 12.5242C4.69197 12.4011 4.76474 12.2868 4.86746 12.1813L11.6476 5.21694C11.8359 5.02348 12.0713 4.92676 12.3538 4.92676C12.6363 4.92676 12.8803 5.02348 13.0858 5.21694C13.2912 5.42798 13.394 5.67859 13.394 5.96877C13.394 6.25895 13.2912 6.50956 13.0858 6.7206L8.07774 11.8647Z' fill='%23280E06'/%3e%3c/svg%3e");
                background-position: center center;
                background-size: 25px 25px;
                background-repeat: no-repeat;
              }
              &.swiper-button-disabled {
                opacity: 0;
                visibility: hidden;
              }
            }
          
            .swiper-button-next {
              margin-right: 6%;
              &:after {
                transform: rotate(180deg);
              }

              @media (max-width: 1400px) {
                margin-right: 0%;
              }
            }
              
          }
            }
      ` }), _jsxs("div", { className: stylesN.newsSwiperMainContainer, children: [_jsxs("div", { className: `${stylesN.mainBannerSlider} ${isHomePage ? stylesN.mainBannerSliderHome : ''}`, children: [_jsxs(Swiper, { breakpoints: {
                                    300: {
                                        slidesPerView: 1
                                    },
                                    768: {
                                        slidesPerView: getSlidePerView()
                                    }
                                }, slidesPerView: getSlidePerView(), loop: true, centeredSlides: true, thumbs: { swiper: thumbsSwiper }, modules: [Navigation, Thumbs, Autoplay], spaceBetween: 10, scrollbar: { draggable: true }, autoplay: { delay: autoPlayDuration, disableOnInteraction: false, pauseOnMouseEnter: false }, onSlideChange: (sw) => { setActiveSlide(sw.realIndex); }, id: 'newsBannerSwiper', className: `${stylesN.newsMainCarousel} headerSwiper`, navigation: {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev'
                                }, onAutoplayTimeLeft: onAutoplayTimeLeft, children: [featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.map((res, index) => {
                                        var _a, _b;
                                        return (_jsxs(SwiperSlide, { children: [_jsx("div", { className: stylesN.bannerImageContainer, children: _jsxs("picture", { children: [_jsx("source", { media: '(max-width: 540px)', srcSet: res.BannerImagesMobile.length > 0 ? (_a = res.BannerImagesMobile) === null || _a === void 0 ? void 0 : _a[0] : getPlaceholderImage(res.Type) }), _jsx("source", { media: '(min-width: 541px)', srcSet: res.BannerImagesMobile.length > 0 ? (_b = res.BannerImages) === null || _b === void 0 ? void 0 : _b[0] : getPlaceholderImage(res.Type) }), _jsx("img", { src: res.BannerImages[0], alt: res.Title })] }) }), _jsxs("div", { className: `${stylesN.slideContentOverlay} ${stylesN['slideContentOverlay--gradinet']}`, children: [_jsx("div", { className: stylesN.slideContentContainer, children: _jsx("div", { className: `${stylesN.slideContentWrapper} ${stylesN['slideContentWrapper--mainSlider']} ${index === activeSlide ? 'slider-content' : ''}`, children: _jsxs("div", { className: stylesN.slideContent, children: [_jsx("span", { className: stylesN.slideSubTitle, children: res.Type }), _jsx("h2", { className: stylesN.slideTitle, children: res.Title }), _jsx("p", { className: stylesN.slideSubTitle, children: res.Description }), _jsx("span", { children: _jsx("div", { onClick: () => handleRedirect(res.Url), className: `${styles.button} ${styles['button--6']} ${isHomePage ? stylesN.buttonHome : ''}`, children: "Read" }) })] }) }) }), _jsx("div", { className: stylesN.gradient })] })] }, res.Title + 'mainSlider'));
                                    }), _jsxs("div", { className: stylesN['newsMainCarousel--navigation'], children: [_jsx("div", { className: 'swiper-button-prev' }), _jsx("div", { className: 'swiper-button-next' })] })] }), _jsx(Swiper, { onSwiper: setThumbsSwiper, loop: true, slidesPerView: 4, modules: [Navigation, Thumbs], className: `newsFeaturedArticlesSwiper ${stylesN.hideInMobile}`, style: { maxWidth: bannerWidth + 'px' }, children: featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.map((res, index) => {
                                    return (_jsx(SwiperSlide, { className: `${stylesN.hideInMobile}`, children: _jsx("div", { className: `${stylesN.slideContentOverlay}`, children: _jsx("div", { className: stylesN.slideContentContainer, children: _jsx("div", { className: `${stylesN.slideContentWrapper} ${stylesN['slideContentWrapper--thubnailSlider']}`, children: _jsx("div", { className: stylesN.thumbGalleryContainer, children: _jsxs("div", { className: stylesN['thumbGalleryContainer--wrapper'], children: [_jsx(Swiperthumbs, { width: activeSlide === index ? progress : 0, isBanner: true }), _jsx("span", { className: activeSlide === index ? stylesN.isActive : '', children: res.Type }), _jsx("h3", { className: activeSlide === index ? stylesN.isActive : '', children: res.Title })] }) }) }) }) }) }, res.Title + 'slideContent'));
                                }) })] }), _jsx("div", { className: stylesN.mobilethumbGalleryContainer, children: featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.map((res, index) => {
                            return _jsx(Swiperthumbs, { width: activeSlide === index ? progress : 0 }, 'Swiperthumbs' + index);
                        }) })] })] }));
}
