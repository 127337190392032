export const typeMapper = {
    News: 'is-news',
    'Match Report': 'is-matchReport',
    Event: 'is-event'
};
export const LeaugeData = [
    { value: 'The Game', label: 'The Game' },
    { value: 'ARL', label: 'ARL' },
    { value: 'Development & Representative', label: 'Development & Representative' },
    { value: 'Clubs & Community', label: 'Clubs & Community' },
    { value: 'School', label: 'School' }
];
export const NewsArticleTypeData = [
    { value: 'All', label: 'All' },
    { value: 'News', label: 'News' },
    { value: 'Match Report', label: 'Match Reports' },
    { value: 'Event', label: 'Events' }
];
export const clubList = [
    {
        value: 'Manurewa Marlins',
        label: 'Manurewa Marlins',
        urlPath: 'manurewa-marlins'
    },
    {
        value: 'Bay Roskill Vikings',
        label: 'Bay Roskill Vikings',
        urlPath: 'bay-roskill-vikings'
    },
    {
        value: 'East Coast Bays Barracudas',
        label: 'East Coast Bays Barracudas',
        urlPath: 'east-coast-bays-barracudas'
    },
    {
        value: 'Ellerslie Eagles',
        label: 'Ellerslie Eagles',
        urlPath: 'ellerslie-eagles'
    },
    {
        value: 'Glenfield Greyhounds',
        label: 'Glenfield Greyhounds',
        urlPath: 'glenfield-greyhounds'
    },
    {
        value: 'Glenora Bears',
        label: 'Glenora Bears',
        urlPath: 'glenora-bears'
    },
    {
        value: 'Hibiscus Coast Raiders',
        label: 'Hibiscus Coast Raiders',
        urlPath: 'hibiscus-coast-raiders'
    },
    {
        value: 'Howick Hornets',
        label: 'Howick Hornets',
        urlPath: 'howick-hornets'
    },
    {
        value: 'Mangere East Hawks',
        label: 'Mangere East Hawks',
        urlPath: 'mangere-east-hawks'
    },
    {
        value: 'Manukau Magpies',
        label: 'Manukau Magpies',
        urlPath: 'manukau-magpies'
    },
    {
        value: 'Marist Saints',
        label: 'Marist Saints',
        urlPath: 'marist-saints'
    },
    {
        value: 'Mt Wellington Warriors',
        label: 'Mt Wellington Warriors',
        urlPath: 'mt-wellington-warriors'
    },
    {
        value: 'New Lynn Stags',
        label: 'New Lynn Stags',
        urlPath: 'new-lynn-stags'
    },
    {
        value: 'Northcote Tigers',
        label: 'Northcote Tigers',
        urlPath: 'northcote-tigers'
    },
    {
        value: 'Otahuhu Leopards',
        label: 'Otahuhu Leopards',
        urlPath: 'otahuhu-leopards'
    },
    {
        value: 'Otara Scorpions',
        label: 'Otara Scorpions',
        urlPath: 'otara-scorpions'
    },
    {
        value: 'Pakuranga Jaguars',
        label: 'Pakuranga Jaguars',
        urlPath: 'pakuranga-jaguars'
    },
    {
        value: 'Papakura Sea Eagles',
        label: 'Papakura Sea Eagles',
        urlPath: 'papakura-sea-eagles'
    },
    {
        value: 'Papatoetoe Panthers',
        label: 'Papatoetoe Panthers',
        urlPath: 'papatoetoe-panthers'
    },
    {
        value: 'Ponsonby Ponies',
        label: 'Ponsonby Ponies',
        urlPath: 'ponsonby-ponies'
    },
    {
        value: 'Pt Chevalier Pirates',
        label: 'Pt Chevalier Pirates',
        urlPath: 'pt-chevalier-pirates'
    },
    {
        value: 'Pukekohe Pythons',
        label: 'Pukekohe Pythons',
        urlPath: 'pukekohe-pythons'
    },
    {
        value: 'Richmond Rovers',
        label: 'Richmond Rovers',
        urlPath: 'richmond-rovers'
    },
    {
        value: 'Rodney Rams',
        label: 'Rodney Rams',
        urlPath: 'rodney-rams'
    },
    {
        value: 'Te Atatu Roosters',
        label: 'Te Atatu Roosters',
        urlPath: 'te-atatu-roosters'
    },
    {
        value: 'Mt Albert Lions',
        label: 'Mt Albert Lions',
        urlPath: 'mt-albert-lions'
    },
    {
        value: 'Tuakau Broncos',
        label: 'Tuakau Broncos',
        urlPath: 'tuakau-broncos'
    },
    {
        value: 'Waiheke Rams',
        label: 'Waiheke Rams',
        urlPath: 'waiheke-rams'
    },
    {
        value: 'Waitemata Seagulls',
        label: 'Waitemata Seagulls',
        urlPath: 'waitemata-seagulls'
    },
    {
        value: 'Waiuku Toa',
        label: 'Waiuku Toa',
        urlPath: 'waiuku-toa'
    }
];
export const locations = [
    { value: '', label: 'All' },
    { value: 'Bay Roskill', label: 'Bay Roskill' },
    { value: 'Browns Bay', label: 'Browns Bay' },
    { value: 'Ellerslie', label: 'Ellerslie' },
    { value: 'Glenfield', label: 'Glenfield' },
    { value: 'Glenora', label: 'Glenora' },
    { value: 'Hibiscus Coast', label: 'Hibiscus Coast' },
    { value: 'Howick', label: 'Howick' },
    { value: 'Mangere East', label: 'Mangere East' },
    { value: 'Manukau', label: 'Manukau' },
    { value: 'Manurewa', label: 'Manurewa' },
    { value: 'Marist', label: 'Marist' },
    { value: 'Mount Albert', label: 'Mount Albert' },
    { value: 'Mount Wellington', label: 'Mount Wellington' },
    { value: 'New Lynn', label: 'New Lynn' },
    { value: 'Northcote', label: 'Northcote' },
    { value: 'Otahuhu', label: 'Otahuhu' },
    { value: 'Otara', label: 'Otara' },
    { value: 'Pakuranga', label: 'Pakuranga' },
    { value: 'Papakura', label: 'Papakura' },
    { value: 'Papatoetoe', label: 'Papatoetoe' },
    { value: 'Point Chevalier', label: 'Point Chevalier' },
    { value: 'Ponsonby', label: 'Ponsonby' },
    { value: 'Pukekohe', label: 'Pukekohe' },
    { value: 'Richmond', label: 'Richmond' },
    { value: 'Te Atatu', label: 'Te Atatu' },
    { value: 'Tuakau', label: 'Tuakau' },
    { value: 'Waiheke', label: 'Waiheke' },
    { value: 'Waitemata', label: 'Waitemata' },
    { value: 'Waiuku', label: 'Waiuku' },
    { value: 'Whangateau', label: 'Whangateau' }
];
export const competitions = [
    { value: '', label: 'All Competition' },
    { value: `Men's Premiership`, label: `Men's Premiership` },
    { value: `Women's Premiership`, label: `Women's Premiership` },
    { value: 'Kiwi Tag', label: 'Kiwi Tag' },
    { value: 'ARL', label: 'ARL' },
    { value: 'Fox Memorial', label: 'Fox Memorial' },
    { value: 'Sharman Cup', label: 'Sharman Cup' },
    { value: 'Premier Reserves', label: 'Premier Reserves' },
    { value: 'Seniors', label: 'Seniors' },
    { value: 'Junior - Youths', label: 'Junior - Youths' }
];
export const grades = [
    { value: '', label: 'All Grade' },
    { value: 'Under 16 Boys', label: 'Under 16 Boys' },
    { value: 'Under 16 Girls', label: 'Under 16 Girls' },
    { value: 'Under 15', label: 'Under 15' },
    { value: 'Under 14 Boys', label: 'Under 14 Boys' },
    { value: 'Under 14 Girls', label: 'Under 14 Girls' },
    { value: 'Under 13', label: 'Under 13' },
    { value: 'Under 12 Boys', label: 'Under 12 Boys' },
    { value: 'Under 12 Girls', label: 'Under 12 Girls' },
    { value: 'Under 11', label: 'Under 11' },
    { value: 'Under 10', label: 'Under 10' },
    { value: 'Under 9', label: 'Under 9' },
    { value: 'Under 8', label: 'Under 8' },
    { value: 'Under 7', label: 'Under 7' },
    { value: 'Under 6', label: 'Under 6' }
];
export const heroSliderImageUrls = [
    { url: '/resources/images/new/hero_slider/heroSlider1.jpg' },
    { url: '/resources/images/new/hero_slider/heroSlider2.jpg' },
    { url: '/resources/images/new/hero_slider/heroSlider3.jpg' },
    { url: '/resources/images/new/hero_slider/heroSlider4.jpg' },
    { url: '/resources/images/new/hero_slider/heroSlider5.jpg' },
    { url: '/resources/images/new/hero_slider/heroSlider6.jpg' }
];
export const ageGroups = {
    juniors: '6-12',
    youths: '13-18',
    seniors: '18-30',
    masters: '30+'
};
export const gameType = [
    { value: 'league', label: 'League' },
    { value: 'weight', label: 'Weight Restricted' },
    { value: 'sharman', label: 'Sharman Cup' },
    { value: 'fox', label: 'Fox Premiership' },
    { value: 'steeleShanks', label: 'Steele Shanks Premiership' },
    { value: 'premiereReserves', label: 'Premier Reserves' },
    { value: 'kiwi-tag', label: 'Kiwi Tag' },
    { value: 'league-tag', label: 'League Tag' }
];
export const ageGroupsList = [
    { value: 'juniors', label: `Juniors (${ageGroups.juniors})`, display: 'Juniors' },
    { value: 'youths', label: `Youths (${ageGroups.youths})`, display: 'Youths' },
    { value: 'seniors', label: `Seniors (${ageGroups.seniors})`, display: 'Seniors' },
    { value: 'masters', label: `Masters (${ageGroups.masters})`, display: 'Masters' },
    { value: 'girls', label: 'Girls', display: 'Girls' },
    { value: 'women-prems', label: 'Women', display: 'Women' }
];
export const characteristics = [
    { value: 'whanau-focus', label: `Family Friendly` },
    { value: 'social-club', label: `Social Club` },
    { value: 'highly-competitive', label: `Highly Competitive` },
    { value: 'heritage-(75+-years)', label: `Heritage` }
];
export const regions = [
    { value: 'All', label: 'All' },
    { value: 'South Auckland', label: 'South Auckland' },
    { value: 'Central Auckland', label: 'Central Auckland' },
    { value: 'East Auckland', label: 'East Auckland' },
    { value: 'North Auckland', label: 'North Auckland' },
    { value: 'West Auckland', label: 'West Auckland' }
];
